import React, { Component } from 'react';
import { withRouter, Link } from "react-router-dom";
import _ from 'lodash';
import { Button, Card, CardHeader, CardFooter, CardBody, CardTitle, CardText } from 'reactstrap';
import classNames from 'classnames';
const colors = ['blue','orange','green','green','green','orange','green','orange','blue'];


class Product extends Component {
  constructor(props){
    super(props);
    this.handleSelect = this.handleSelect.bind(this);
  }


  setProductContainerDimensions() {
    setTimeout(() => {
      if(this.productContainer){
        this.productContainer.style.minHeight = `${this.props.className === 'product-featured' ? this.productContainer.offsetHeight : this.props.isGrid ? 210 : 150}px`;
        this.productContainer.style.margin = "15px 0px";
      }
    }, 0);
  }

  resize = () => this.clearProductContainerDimensions()
  clearProductContainerDimensions() {
    this.productContainer.style.minWidth = '';
    setTimeout(() => {
      this.setProductContainerDimensions();
    }, 0);
  }

  componentDidMount(){
    this.setProductContainerDimensions();
    window.addEventListener('resize', this.resize);
  }
  componentDidUpdate(prevProps){
    if(_.get(prevProps, 'isGrid') !== _.get(this.props, 'isGrid')){
      this.clearProductContainerDimensions();
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }


  handleSelect(e){
    this.props.log_transaction("clicked", "product", this.props.data.id, this.props.data.name);
    if(this.props.editProposal) {
      e.preventDefault();
      e.stopPropagation();
    }
    if(e.target.classList.contains('sow')) {
      if(e.target.classList.contains('added')) {
        this.props.handleSowBuilderRemove(this.props.data, e)

      }
      else {
        this.props.handleSowBuilderAdd(this.props.data, e)
      }
    }
    else {
      this.props.setPageDetail(this.props.data);
    }
  }
  render(){
    const item = this.props.data;
    //const subtitle = this.props.renderProductPriceTime(item);
    return(
        <div ref={node => this.productContainer = node} className={classNames('product-container', this.props.className, this.props.isGrid ? 'col-lg-4' : 'col-lg-12')}>
          <div>
            <Link to={`/item/${item.id}`} onClick={this.handleSelect}>
              <Card className={classNames(colors[((this.props.index)% colors.length)])}>
                <CardHeader>
                  <CardTitle>{item.name}</CardTitle>
                  {/*{subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}*/}
                </CardHeader>
                <CardBody>
                  <CardText>
                    <div className="description-short">{_.truncate(item.description, {length: 280})}</div>
                    <div className="description-full">{item.description}</div>
                  </CardText>
                </CardBody>
                <CardFooter>
                  <Button>Learn More</Button>
                  {/*{
                    this.props.isSowBuilderAdded(item) ?
                        <Button className="sow added">Added to SOW</Button> :
                        (
                            this.props.isSowBuilderLimit()
                              ? <Popup trigger={<Button className="disabled">Add to SOW</Button>} inverted content='You have already added 3 items to your SOW.' />
                              : <Button className="sow add">Add to SOW</Button>
                        )
                  }*/}
                </CardFooter>
              </Card>
            </Link>
            {this.props.isSowBuilderAdded(item) && <div className="sowBuilder active"><Button className="sow added" onClick={this.handleSelect}>Added to SOW</Button></div>}
          </div>
        </div>
    );
  }
}

export default withRouter(Product);
