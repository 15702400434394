import React, { Component } from "react";
import { connect } from "react-refetch";
import Constants from "./Constants.js";
import _ from "lodash";
import Site from "./components/Site.js";
//import ldata from './catalog-prototype.js';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import 'semantic-ui-css/semantic.min.css';
import { Tools } from '@workrails/workrails-interpreter';
const jsforce = require('jsforce');
library.add(fas);
var toBuffer = require('blob-to-buffer')


const getUser = function() {
  const user = localStorage.getItem("user/1i9");
  return user ? JSON.parse(user) : {user: {}};
};

const setUser = function(user) {
  localStorage.setItem("user/1i9", JSON.stringify(user));
};

Tools.configure({
  isProd: true,
  getUser: (() => { if(getUser()){ return getUser().user } else { return undefined } }),
  getCatalog: (() => { return Constants.CATALOG_ID }),
  companyId: '7953886e-6b1f-4076-9433-c6ecb49baf90',
  debug: window.location.href.includes('localhost') || window.location.href.includes('catalogs.dev'),
});

const sfData = {};

const isSalesforce = function(){
  return window.location.href.includes('force') && window.location.href.indexOf('.com') > window.location.href.indexOf('force');
};

/*const getUserAuth = function(user) {
  if(!user && !getUser()){
    return {'Content-type':'application/json', 'Authorization':Constants.AUTH};
  }

  return {
    'Content-type': 'application/json',
    'Authorization': (user||getUser()).authorization
  };
};*/

let conn;

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_public: false,
      initialized: false,
      loading: true,
      dimmed: false,
      data: {},
      geo: {}
    };
    this.log_transaction = this.log_transaction.bind(this);
    this.clearUser = this.clearUser.bind(this);
    this.isLoading = this.isLoading.bind(this);
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.isDimmed = this.isDimmed.bind(this);
  }


  sfCallback(err, res, value){
    if(err){
      console.log(err);
      return;
    }
    sfData[value] = res.records;
  }

  sfCreateOpportunity(opp, doc, pdf, docName, staffing, order, callback){
    try{
      conn.sobject("Opportunity").create(opp, function(err, ret){
        if(err){
          return callback(err.toString());
        }
        staffing.Opportunity__c = ret.id;
        order.Opportunity__c = ret.id;
        toBuffer(doc, function (err, buffer) {
          if (err) { return callback(err.toString()); }
          var base64data = new Buffer(buffer).toString('base64');
          conn.sobject('Attachment').create({
                  ParentId: ret.id,
                  Name : docName + ".docx",
                  Body: base64data,
                  ContentType : "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
              },
              function(err, uploadedAttachment) {
          if (err) { return callback(err.toString()); }
            conn.sobject("Services_Products__c").create(staffing, function(err, ret){
              if(err){
                console.log(err);
                return callback(err.toString());
              }
              conn.sobject("VoD_Order__c").create(order, function(err, ret){
                if(err){
                  return callback(err.toString());
                }
                return callback(true);
              });

            });
          });
         })
      });
    }catch(e){
    }
  }

  isLoggedIn() {
    return this.state.user.id !== undefined;
  }

  clearUser() {
    this.setState({ user: {} });
    localStorage.removeItem("user/1i9");
    if (this.props.signinRequestReset) this.props.signinRequestReset();
  }

  componentWillMount() {
    if(getUser().authorization){
      this.props.tokenLazyFetch();
    }
  }


  componentDidMount() {
    if(isSalesforce()){
      conn = new jsforce.Connection({ accessToken: localStorage.getItem('sf_session') });
      conn.query('SELECT Name, Id FROM Account',(err,res)=>this.sfCallback(err,res,'accounts'));
      conn.query('SELECT ApiName FROM OpportunityStage',(err,res)=>this.sfCallback(err,res,'stages'));
      sfData.regions = JSON.parse(localStorage.getItem('sf_regions'));
      sfData.editions = JSON.parse(localStorage.getItem('sf_editions'));
      sfData.revenuedepts = JSON.parse(localStorage.getItem('sf_revenuedepts'));
    }
    this.setState({ user: getUser() });
    this.setState({ startTime: new Date().getTime() });
    if(window.location.pathname === '/'){
     this.log_transaction("viewed", "page", "home");
    }
  }

  componentDidUpdate(prevProps) {
    if (
      _.get(prevProps, "catalogFetch.pending") &&
      !_.get(this.props, "catalogFetch.pending")
    ) {
      const data = _.get(this.props, "catalogFetch.value.data");
      this.setState({ loading: false, initialized: true });
      document.title = data.name;
      if (
        _.get(this.props, "locationFetch.pending") === false ||
        new Date().getTime() - this.state.startTime > 5000
      ) {
      }
    }
    if (
      _.get(prevProps, "signInFetch.pending") &&
      !_.get(this.props, "signInFetch.pending")
    ) {
      if (_.get(this.props, "signInFetch.rejected") === false) {
        const user = _.get(this.props, "signInFetch.value.data");
        const meta = _.get(this.props, "signInFetch.meta");
        const token = meta.response.headers.get('Authorization')
        const userData = {
          userId: user.id,
          authorization: token,
          id: user.id,
          user,
        }
        this.setState({ user: userData });
        setUser(userData);
      }
    }
    if (_.get(prevProps, 'proposalsFetch.pending') && !_.get(this.props, 'proposalsFetch.pending')) {
      this.isLoading(false);
    }
    if (_.get(prevProps, 'tokenFetch.pending') && !_.get(this.props, 'tokenFetch.pending')) {
      const token = _.get(this.props, "tokenFetch.value.data");
      if(token) {
        const user = this.getUser();
        user.authorization = token;
        this.setState({ user });
        setUser(user);
      }
      else {
        this.clearUser();
      }
    }
  }

  getSfData(){
    return sfData;
  }

  getUser() {
    if (getUser()) {
      return getUser();
    }
    const user = {};
    user.email = "guest@generic.com";
    user.id = "cheese";
    return user;
  }

  isLoading(loading) {
    if (loading !== undefined) {
      this.setState({ loading: loading });
      return;
    }
    return this.state.loading;
  }

  renderLoading() {
    if (this.state.loading) {
      return (
          <FontAwesomeIcon icon="spinner" size="3x" spin className="loading"/>
      )
    }
  }

  isDimmed(dimmed) {
    if (typeof dimmed === "undefined") {
      return this.state.dimmed;
    }
    this.setState({ dimmed });
  }

  renderDimmed() {
    if (!this.state.dimmed) return null;

    return <div className="dimmed" />;
  }

  log_transaction(action, type, id, hname) {
    Tools.logTransaction(action, type, id, hname);
  }

  render() {
    if(!this.state.initialized) {
      return this.renderLoading();
    }
    return (
      <div className="App">
        <Site
          sfCreateOpportunity={this.sfCreateOpportunity}
          getSfData={this.getSfData}
          isSalesforce = {isSalesforce()}
          clearUser={this.clearUser}
          is_public={this.state.is_public}
          productRowsFetch={this.props.productRowsFetch}
          data={this.props.catalogFetch.value.data}
          productRowsLazyFetch={this.props.productRowsLazyFetch}
          proposalLazyCreate={this.props.proposalLazyCreate}
          proposalCreate={this.props.proposalCreate}
          proposalLazyUpdate={this.props.proposalLazyUpdate}
          proposalUpdate={this.props.proposalUpdate}
          proposalLazyDelete={this.props.proposalLazyDelete}
          proposalDelete={this.props.proposalDelete}
          proposalsLazyFetch={this.props.proposalsLazyFetch}
          proposalsFetch={this.props.proposalsFetch}
          proposalLazyFetch={this.props.proposalLazyFetch}
          proposalFetch={this.props.proposalFetch}
          proposalQuoteLazyCreate={this.props.proposalQuoteLazyCreate}
          proposalQuoteCreate={this.props.proposalQuoteCreate}
          proposalQuotesFetch={this.props.proposalQuotesFetch}
          log_transaction={this.log_transaction}
          lazySignIn={this.props.lazySignIn}
          signInFetch={this.props.signInFetch}
          isLoggedIn={this.isLoggedIn}
          isLoading={this.isLoading}
          isDimmed={this.isDimmed}
          user={this.state.user ? this.state.user.user : null}
          sendPasswordReset={this.props.lazySendPasswordReset}
        />
        {this.renderLoading()}
        {this.renderDimmed()}
      </div>
    );
  }
}
/*
      url: Constants.API_BASE + 'catalogs/' + Constants.CATALOG_ID,
      method: 'GET',
      headers: {
        'cache-control': 'no-cache',
        'Content-Type': 'application/json',
        'token': Constants.AUTH,
      }
    catalogFetch: {
      url: "https://dev.api.workrails.com/beta/catalogs/44d4d1d0-8a63-44bf-b2de-acd17ff43682",
      method: 'GET',
      headers: {
        'cache-control': 'no-cache',
        'Content-Type': 'application/json',
        'token': Constants.AUTH,
      }
    },*/

export default connect(props => {
  return {
    catalogFetch: {
      url: Constants.API_BASE + "/catalogs/" + Constants.CATALOG_ID,
      method: "GET",
      headers: {
        "cache-control": "no-cache",
        "Content-Type": "application/json",
        token: Constants.AUTH
      }
    },
    tableListLazyFetch: () => ({
      tableListFetch: {
        url: Constants.APP_BASE + `/api/v1/lists/search?companyId=${Constants.COMPANY_ID}&type=itemTemplateCustomTableList&full=true`,
        method: 'GET',
        force: true,
        headers: {
          Authorization: getUser().authorization
        },
        andThen: results => {
          return {
            proposalQuotesFetch: {
              url: Constants.APP_BASE + `/api/v1/attachments/attachable-id-list?internalType=proposal`,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              force: true,
              body: `{"data":[${results.data.map(p => '"' + p.id + '"').join(',')}]}`,
            }
          };
        }
      }
    }),
    productRowsLazyFetch: productId => ({
      productRowsFetch: {
        url: `https://app.workrails.com/api/v1/product-rows/product-id/${productId}`,
        headers: {
          Authorization: "ccc10ad7-b5aa-4c1d-8cbb-b4c3737efc8d"
        },
        force: true
      }
    }),
    lazyLogTransaction: transaction => ({
      logTransactionFetch: {
        url: "https://dev.api.workrails.com/beta/transactions/create",
        method: "POST",
        headers: {
          token: Constants.AUTH,
          "Content-Type": "application/json"
        },
        body: `{"data": ${JSON.stringify(transaction)}}`
      }
    }),
    lazySignIn: payload => ({
      signInFetch: {
        url: Constants.APP_BASE + `/api/v1/auth/login`,
        force: true,
        method: "POST",
        headers: {
          "Content-Type": "application/json"
        },
        body: `${JSON.stringify(payload)}`
      },
      signinRequestReset: () => ({
        signinRequest: {
          value: null
        }
      })
    }),
    tokenLazyFetch: () => ({
      tokenFetch: {
        url: Constants.APP_BASE + `/api/v1/auth/token`,
        method: "GET",
        force: true,
        headers: {
          Authorization: getUser().authorization
        },
        andThen: results => {
          return {
            tableListFetch: {
              url: Constants.APP_BASE + `/api/v1/lists/search?companyId=${Constants.COMPANY_ID}&type=itemTemplateCustomTableList&full=true`,
              method: 'GET',
              force: true,
              headers: {
                Authorization: getUser().authorization
              },
            }
          };
        }
      },
    }),
    proposalsLazyFetch: () => ({
      proposalsFetch: {
        url: Constants.APP_BASE + `/api/v1/documents/search?type=proposal&userId=${getUser().user.id}&parentId=${Constants.CATALOG_ID}`,
        method: 'GET',
        force: true,
        headers: {
          Authorization: getUser().authorization
        },
        andThen: results => {
          return {
            proposalQuotesFetch: {
              url: Constants.APP_BASE + `/api/v1/attachments/attachable-id-list?internalType=proposal`,
              method: 'POST',
              headers: {
                'Content-Type': 'application/json'
              },
              force: true,
              body: `{"data":[${results.data.map(p => '"' + p.id + '"').join(',')}]}`,
            }
          };
        }
      }
    }),
    proposalLazyFetch: (proposalId) => ({
      proposalFetch: {
        url: Constants.APP_BASE + `/api/v1/documents/search?type=proposal&id=${proposalId}`,
        method: 'GET',
        force: true,
        headers: {
          Authorization: getUser().authorization
        },
      }
    }),
    proposalLazyCreate: (proposal) => ({
      proposalCreate: {
        url: Constants.APP_BASE + "/api/v1/documents/create",
        method: 'POST',
        force: true,
        headers: {
          Authorization: getUser().authorization
        },
        body: `${JSON.stringify({data: proposal})}`,
      }
    }),
    proposalLazyUpdate: (proposal) => ({
      proposalUpdate: {
        url: Constants.APP_BASE + `/api/v1/documents/document-id/${proposal.id}/update`,
        method: 'PUT',
        force: true,
        headers: {
          Authorization: getUser().authorization
        },
        body: `${JSON.stringify({data: proposal})}`,
      }
    }),
    proposalLazyDelete: (proposalId) => ({
      proposalDelete: {
        url: Constants.APP_BASE + `/api/v1/documents/document-id/${proposalId}/delete`,
        method: 'DELETE',
        force: true,
        headers: {
          Authorization: getUser().authorization
        },
      }
    }),
    proposalQuoteLazyCreate: (proposal, data) => ({
      proposalQuoteCreate: {
        url: Constants.APP_BASE + `/api/v1/attachments/attachable-id/${proposal.id}?internalType=proposal`,
        method: 'POST',
        force: true,
        headers: {
          'Content-Type': null,
          Authorization: getUser().authorization
        },
        body: data,
      }
    }),
    lazySendPasswordReset: (payload) => ({
      passwordResetFetch: {
        url: `${Constants.APP_BASE}/api/v1/emails/send/password-reset`,
        method: 'POST',
        force: true,
        refreshing: true,
        headers: {
          'Content-Type': 'application/json',
        },
        body: `${JSON.stringify(payload)}`,
      }
    }),
  };
})(App);
