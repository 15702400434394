import React, { Component } from 'react';
import { Form, InputGroup, Input, Button } from 'reactstrap';
class Login extends Component {
  constructor(props){
    super(props);

    this.state = {
      forgotPW: false,
    }

    this.forgotPassword = this.forgotPassword.bind(this);
    this.backToMain = this.backToMain.bind(this);
  }

  forgotPassword(){
    this.setState({forgotPW: true});
  }

  backToMain(){
    this.setState({forgotPW: false});
  }

  render(){
    if(this.state.forgotPW){
      return(
          <div className="login">
            <Button className="back" onClick={this.backToMain}>Back</Button>
            Forgot Password
            <Input type="text" onChange={this.props.formManager} name="email" placeholder="email" />
            <Button onClick={this.props.onForgotPWSubmit}>Send Reset Email</Button>
            {this.props.loginError && <p className="alert-danger">{this.props.loginError}</p>}
            {this.props.loginSuccess && <p className="alert-success">{this.props.loginSuccess}</p>}
          </div>
      );
    }
    return(
      <Form className="login" onSubmit={(e) => { e.preventDefault(); this.props.onLoginSubmit()}}>
        <InputGroup>
          <Input type="text" onChange={this.props.formManager} name="username" placeholder="email" />
        </InputGroup>
        <InputGroup>
          <Input type="password" onChange={this.props.formManager} name="password" placeholder="password" />
        </InputGroup>
        <Button type="submit" onClick={this.props.onLoginSubmit}>Login</Button>
        {this.props.loginError && <p className="alert-danger">{this.props.loginError}</p>}
        <Button className="forgot" onClick={this.forgotPassword}>Forgot Your Password?</Button>
      </Form>
    );
  }
}

export default Login;
