import React, { Component } from "react";
import { Link } from "react-router-dom";
import Flatpickr from 'react-flatpickr';
import ReactHtmlParser from "react-html-parser";
import {Modal, ModalHeader, ModalBody, ModalFooter, Alert} from 'reactstrap';
import sfLogo from '../../assets/Salesforce-Logo.png';
import _ from "lodash";
import Select from 'react-select';
import {
  Container,
  Button,
  Card,
  Input,
  CardHeader,
  CardBody,
  CardTitle,
  CardSubtitle,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import WorkrailsInterpreter from '@workrails/workrails-interpreter';
//import WorkrailsInterpreter from './WorkrailsInterpreter';
const DATE_FORMATS = {flatPickr: 'n/j/Y', formValue: 'M/D/YYYY'};

class ProductDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {
      activeTab: "main",
      tabs: [],
      salesforce: {
        closeDate: new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate() + 7),
        revenuedept: [],
        edition: [],
      },
      hasSubmitted: false,
      missingFields: [],
      loading: false,
      productRows: "",
      dropdownOpen: false,
      discountValue: 0,
    };
    this.handleTabClick = this.handleTabClick.bind(this);
    this.handleCheckOption = this.handleCheckOption.bind(this);
    this.isDropdownOptionDisabled = this.isDropdownOptionDisabled.bind(this);
    this.isCheckboxOptionDisabled = this.isCheckboxOptionDisabled.bind(this);
    this.isTextDisabled = this.isTextDisabled.bind(this);
    this.renderRow = this.renderRow.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
    this.sfHandleChange = this.sfHandleChange.bind(this);
    this.onSfSubmit = this.onSfSubmit.bind(this);
  }

  onSfSubmit(){
    this.setState({hasSubmitted: true});
    if(!this.areFieldsValid()){
      return;
    }else{
      this.setState({hasSubmitted: false});
    }
    this.props.sfCreateOpportunity({ Name: this.props.sowBuilderOppName, StageName: this.state.salesforce.stage.value, closeDate: moment(this.state.salesforce.closeDate).format('YYYY-MM-DD'), AccountId: this.props.sfAccount.value, Region__c: this.state.salesforce.region.label, Revenue_Department__c: this.state.salesforce.revenuedept.map((revenuedept) => { return revenuedept.label}).join(';'), Editions__c:  this.state.salesforce.edition.map((edition)=> { return edition.label}).join(';'), Managed_Services_Renewal__c: true},
      this.state.salesforce.docname ? this.state.salesforce.docname :  this.props.sowBuilderOppName + '-' + moment().format(DATE_FORMATS.formValue),
      {
        Services_Role__c:this.props.sowBuilderOverageHours > 0 ? 'a2DK0000001NbXR' : 'a2D50000000laCf',
	      Week_Starting__c:moment(this.state.salesforce.startDate).format('YYYY-MM-DD'),
        Duration__c:this.props.sowBuilderTerm,
        Hours__C: this.props.sowBuilderHoursPerMonth,
        Requested_Resource_Location__c: this.state.salesforce.reqresource.label,
        RecordTypeId: '012500000009tGm',
        Product__c: this.props.data.name.includes('Vault') ? 'Vault' : 'CRM',
      }, {
        SOW_Total__c: this.props.totalCost.substring(1).replace(',',''),
      });
  }

  areFieldsValid(){
    const { salesforce } = this.state;
    return salesforce.revenuedept.length > 0 && salesforce.region !== undefined && salesforce.edition.length > 0 && (!salesforce.docname || salesforce.docname.length > 0) && salesforce.reqresource !== undefined;
  }


  componentDidMount() {
    this.props.setPageDetail(this.props.data);
    if(this.props.isSalesforce){
      const { salesforce } = this.state;
      const defaultStageName=this.props.getSfData().stages.find((row) => row.ApiName=== "Awaiting Signature").ApiName;
      salesforce.stage = {label:defaultStageName, value:defaultStageName};
      salesforce.region = {label: this.props.getSfData().regions[0], value: this.props.getSfData().regions[0]};
      salesforce.reqresource = {label: this.props.getSfData().regions[0], value: this.props.getSfData().regions[0]};
      this.setState({salesforce});
    }
    this.props.log_transaction("viewed", "product", this.props.data.id, this.props.data.name);
    const item = this.props.data;
    const tabs = [
      {
        title:
          item.main_tab_label && item.main_tab_label.trim().length > 0
            ? item.main_tab_label
            : "Main",
        content: []
      }
    ];
    this.setState({ activeTab: tabs[0].title });
    let currentTab = 0;
    item.rows.forEach(row => {
      if (row.options.addToCatalogTab === true) {
        tabs.push({
          title: row.label,
          content: [row]
        });
        currentTab = tabs.length - 1;
      } else if (
        row.row_type === "style:header" ||
        row.options.hasOwnHeader === true
      ) {
        currentTab = 0;
        tabs[0].content.push(row);
      } else {
        tabs[currentTab].content.push(row);
      }
    });
    this.setState({ tabs });
    window.scrollTo(0, 0);

    this.props.setSowBuilderPreviewHeight();
    window.addEventListener("resize", this.props.setSowBuilderPreviewHeight);
    /*if (!this.props.isSowBuilderPreviewShowing()) {
      this.props.toggleSowBuilderPreview();
    }*/
  }

  componentDidUpdate(prevProps) {
    if (
        _.get(prevProps, "productRowsFetch.pending") &&
        !_.get(this.props, "productRowsFetch.pending")
    ) {
      this.setState({loading: false});
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.props.setSowBuilderPreviewHeight);

    if (this.props.isSowBuilderPreviewShowing()) {
      this.props.toggleSowBuilderPreview();
    }
  }

  handleTabClick(e) {
    this.setState({ activeTab: e.target.value });
  }

  getTabs() {
    const tabs = this.state.tabs;
    return (
      <div className="productDetailTabDisplay">
        <div className="tabList">
          {tabs.map(t => {
            const activeClass =
              t.title === this.state.activeTab ? "active" : "";
            return (
              <button
                onClick={this.handleTabClick}
                value={t.title}
                className={activeClass}
                key={t.title}
              >
                {t.title}
              </button>
            );
          })}
        </div>
        <div className="tabContent">
          {tabs
            .find(tab => tab.title === this.state.activeTab)
            .content.map((item, index) => {
              return this.renderRow(item);
            })}
        </div>
      </div>
    );
  }

  getDiscountKey = () => `${this.props.data.id}_discount`

  handleDiscountChange = (e) => {
    const { value } = e.target
    const numberValue = parseFloat(value) || 0
    if (numberValue < 0 || numberValue > 20) {
      return false
    }
    this.props.handleProductOptionSelection({ id: this.getDiscountKey() }, numberValue)
  }

  handleCheckOption(value) {
    const { options } = this.state;
    if (options.includes(value)) {
      options.splice(options.indexOf(value));
    } else {
      options.push(value);
    }
    this.setState({ options });
  }

  sfHandleChange(e){
    const salesforce = this.state.salesforce;
    salesforce[e.target.name] = e.target.value;
    this.setState({salesforce});
  }

  sfHandleSelectChange(e, val){
    const salesforce = this.state.salesforce;
    if(val === "region"){
      salesforce.reqresource = e;
    }
    salesforce[val] = e;
    this.setState({salesforce});
  }

  toggleDropdown() {
    this.setState({ dropdownOpen: !this.state.dropdownOpen });
  }


  renderRow(row) {
    if (
      this.state.tabs.length > 0 &&
      this.state.activeTab === this.state.tabs[0].title &&
      !row.is_public
    ) {
      return null;
    }
    if (row.description === "Regional Pricing") {
      if (
        row.control &&
        row.control.trim() !== "" &&
        row.control.trim() !== '""'
      ) {
        return (
          <div key={row.id} className={`section ${row.row_type}`}>
            <h2>
              {row.label}:{row.control}
            </h2>
          </div>
        );
      } else {
        return null;
      }
    }
    if(row.row_type === "text"){
      if(row.options.hasQuestionnaire === true){
        return <div key={row.id} className={`section ${row.row_type}`}>{row.label}: <Input type="number" invalid={!row.label.includes('Overage') && this.props.getProductOptionState(row) === undefined && this.props.requestedOpenOpp} placeholder="Enter Value" size="sm" value={this.props.selectedProductOptions[row.id]||''} onChange={(e) => this.props.handleProductOptionSelection(row, e.target.value)} /></div>;
      } else {
        const val = row.control ? row.control.substring(1,row.control.length-1) : '';
        return <div key={row.id} className={`section ${row.row_type}`}>{row.label.replace("[CUSTOMER FACING]", "")}:  {val}</div>
      }
    }
    if (row.row_type === "dropdown") {
      const dropdownOptions = _.map(row.options.items, i => {
        return { value: JSON.parse(i.value).value, label: i.label };
      });
      const dropdownSelection = dropdownOptions.find(i => i.value === this.props.selectedProductOptions[row.id]);
      return (
        <div key={row.id} className={`section ${row.row_type}`}>
          <label htmlFor={`row-select-${row.id}`}>{row.label}:</label>

          <Dropdown className={this.props.getProductOptionState(row) === undefined && this.props.requestedOpenOpp ? "invalid" : ""} isOpen={this.state.dropdownOpen} toggle={this.toggleDropdown} size="sm">
            <DropdownToggle  caret outline className={dropdownSelection ? 'selected' : ''}>{dropdownSelection ? dropdownSelection.label : 'Choose Option'}</DropdownToggle>
            <DropdownMenu>
              {_.map(dropdownOptions, (o, i) => {
                return (
                    <DropdownItem key={`row-select-${row.id}-${i}`} onClick={() => this.props.handleProductOptionSelection(row, o.value)}>{o.label}</DropdownItem>
                );
              })}
            </DropdownMenu>
          </Dropdown>
        </div>
      );
    }
    if (row.row_type === "checkbox") {
      if (row.options.hasQuestionnaire === true) {
        return (
          <div key={row.id} className="section">
            <h2>{row.label.replace("[CUSTOMER FACING]", "")}</h2>
            {row.options.items.map(check => {
              return (
                <div key={check.value} className="checkoption">
                  <input
                    type="checkbox"
                    onChange={this.handleCheckOption.bind(this, row, check.label)}
                    checked={this.props.options[row.id].includes(check.label)}
                  />
                  {check.label}
                </div>
              );
            })}
          </div>
        );
      }
      return (
        <div key={row.id} className={`section ${row.row_type}`}>
          <h2>{row.label}</h2>
          {row.options.items.map(check => {
            return (
              <div key={check.value}>
                <div className="ui checkbox">
                  <input
                      type="checkbox"
                      onChange={this.handleCheckOption.bind(this, check.label)}
                      checked={this.state.options.includes(check.label)}
                  />
                    <label>{check.label}</label>
                </div>
              </div>
            );
          })}
        </div>
      );
    } else if (row.row_type === "component:faqs") {
      return (
        <ul className="faqs">
          {row.control.map(f => {
            return (
              <li key={f.id} className="faq">
                <div className="question">{f.question}</div>
                <div className="answer">{f.answer}</div>
              </li>
            );
          })}
        </ul>
      );
    }
    let value = row.control;
    if (row.control && row.control[0] === '"') {
      value = value.substring(1, row.control.length - 1);
    }
    return value && value.length > 0 ? (
      <div key={row.id} className={`section ${row.row_type}`}>
        {row.label !== "Exhibit 1" && <h2>{row.label}</h2>}
        <div>{ReactHtmlParser(value.replace(/\\"/g, '"').replace(this.props.buildSowBuilderDownloadVariableTagRegex("Exhibit"), '$1'))}</div>
      </div>
    ) : null;
  }
  handleDateChange(date){
    const salesforce = this.state.salesforce;
    salesforce.closeDate = date;
    this.setState({ salesforce })
  }





  isDropdownOptionDisabled(row, option) {
    if (row.row_type === "dropdown" && row.label.toLowerCase().includes('hour')) {
      if(option.label.toLowerCase() === '2 hours' || option.label.toLowerCase() === '4 hours') {
        const quarterRow = this.props.data.rows.find(r => r.row_type === "dropdown" && r.label.toLowerCase().includes('quarter'));
        if(quarterRow) {
          const selectedQuarter = this.props.selectedProductOptions[quarterRow.id]
          if(selectedQuarter && selectedQuarter.value.quarter === 1) {
            return true;
          }
        }
      }
    }

    return false;
  }

  isCheckboxOptionDisabled(row, option) {
    if(row.label.toLowerCase() === 'roles') {
      const partnerDetails = this.props.getPartnerDetails(this.props.data, this.props.selectedProductOptions);
      if(partnerDetails) {
        const roleKey = option.label.replace(/.*\((.*?)\)$/, '$1');
        const roleRateList = partnerDetails.tableLists.find(list => list.name.toLowerCase() === `${roleKey.toLowerCase()} rate`);
        if (roleRateList) {
          const roleRateId = partnerDetails.tableMapping.dropdowns[roleRateList.id][0];
          const roleRateRow = roleRateList.list_rows.find(row => row.id === roleRateId);
          const roleRate = roleRateRow.key.replace('$', '').replace(',', '');
          return isNaN(roleRate);
        }
      }
      return true;
    }
    else if (this.props.data.name === 'Elements Assisted' && row.row_type === "checkbox" && ((_.startsWith(option.label, 'Services Experience') || _.startsWith(option.label, 'Advocate Engagement')))) {
      return true;
    }

    return false;
  }


  isTextDisabled(row) {
    if(row.label.includes('Hours')) {
      const roleHoursKey = row.label.replace(/^(.*?)\s+Hours$/, '$1');
      if(roleHoursKey) {
        const rolesRow = this.props.data && this.props.data.rows.find(row => row.row_type === 'checkbox' && row.label.toLowerCase() === 'roles');
        if (rolesRow) {
          const partnerDetails = this.props.getPartnerDetails(this.props.data, this.props.selectedProductOptions);
          if (partnerDetails) {
            const roleOption = rolesRow.options.items.find(o => o.label.replace(/.*\((.*?)\)$/, '$1') === roleHoursKey);
            if (roleOption) {
              const roleKey = roleOption.label.replace(/.*\((.*?)\)$/, '$1');
              const roleRateList = partnerDetails.tableLists.find(list => list.name.toLowerCase() === `${roleKey.toLowerCase()} rate`);
              if (roleRateList) {
                const roleRateId = partnerDetails.tableMapping.dropdowns[roleRateList.id][0];
                const roleRateRow = roleRateList.list_rows.find(row => row.id === roleRateId);
                const roleRate = roleRateRow.key.replace('$', '').replace(',', '');
                return isNaN(roleRate);
              }
            }
          }
          return true;
        }
      }
    }
    return false;
  }

  getModal(){

    if(this.props.finalMsg === true){
      return <Alert color="success">Opportunity Successfully Created!</Alert>
    }else if(this.props.finalMsg){
      return <Alert color="danger">{this.props.finalMsg}</Alert>
    }else if(this.props.sfLoading){
      return <Alert color="primary">Creating Opportunity...</Alert>
    }
    return (
        <Modal className="opportunity" isOpen={this.props.isSfModalOpen} toggle={this.props.toggleSfModal}>
          <ModalHeader toggle={this.props.toggleSfModal}>
            <img src={'https://veeva.workrails.com' + sfLogo} alt='logo_not_found' />
            Creating Opportunity <span style={{color:"blue"}}>{this.props.sowBuilderOppName}</span> for Account: <span style={{color:"purple"}}>{this.props.sfAccount && this.props.sfAccount.label}</span>
          </ModalHeader>
          <ModalBody>
            <div>
                <div className="parent">
                  <div className="navOpp">
                    <div className="sfIcon" />
                  </div>
                  <div className="mainOpp">
                    <div className="inputOpp">
                      <div className="left">
                        <div className={this.state.salesforce.revenuedept.length === 0 && this.state.hasSubmitted ? 'input invalid': 'input'} >
                          <label>Revenue Department:</label>
                          <Select isMulti={true} value={this.state.salesforce.revenuedept} onChange={(e) => this.sfHandleSelectChange(e,'revenuedept')}
                            options= {this.props.getSfData().revenuedepts.map(function(dept) {
                              return {label:dept, value:dept}
                            })} />
                        </div>
                        <div className="input">
                          <label>Week Starting:</label>
                          <Flatpickr
                            id='startDate'
                            value={this.state.salesforce.startDate ? this.state.salesforce.startDate : this.props.sowBuilderDate}
                            options={{dateFormat: DATE_FORMATS.flatPickr, minDate: moment().format(DATE_FORMATS.formValue)}}
                            onChange={(date) => this.handleDateChange(date)}
                          />
                        </div>
                        <div className="input">
                          <label>Close Date:</label>
                          <Flatpickr
                            id='closeDate'
                            value={this.state.salesforce.closeDate}
                            options={{dateFormat: DATE_FORMATS.flatPickr, minDate: moment().format(DATE_FORMATS.formValue)}}
                            onChange={(date) => this.handleDateChange(date)}
                          />
                        </div>
                        <div className="input">
                          <label>Stage Name:</label>
                          <Select value={this.state.salesforce.stage} onChange={(e) => this.sfHandleSelectChange(e,'stage')}
                            options= {this.props.getSfData().stages.map(function(dept) {
                              return {label:dept.ApiName, value:dept.ApiName}
                            })} />
                        </div>
                        <div className={this.state.salesforce.region === undefined && this.state.hasSubmitted ? 'input invalid': 'input'} >
                          <label>Region:</label>
                          <Select value={this.state.salesforce.region} onChange={(e) => this.sfHandleSelectChange(e,'region')}
                            options= {this.props.getSfData().regions.map(function(dept) {
                              return {label:dept, value:dept}
                            })} />
                        </div>
                        <div className={this.state.salesforce.edition.length === 0 && this.state.hasSubmitted ? 'input invalid': 'input'} >
                          <label>Edition:</label>
                          <Select isMulti={true} value={this.state.salesforce.edition} onChange={(e) => this.sfHandleSelectChange(e,'edition')}
                            options= {this.props.getSfData().editions.map(function(dept) {
                              return {label:dept, value:dept}
                            })} />
                        </div>
                        <div className="input">
                          <label>Document Name</label>
                          <Input className="input" placeholder="" name="docname" value={this.state.salesforce.docname ? this.state.salesforce.docname : this.props.sowBuilderOppName + '-' + moment().format(DATE_FORMATS.formValue)} onChange={this.sfHandleChange} required/>
                        </div>
                      </div>
                      <div className="right">
                        <div className="input">
                          <label>Contract Service Budget:</label>
                          <Input placeholder="" name="name" value={this.props.totalCost} disabled/>
                        </div>
                        <div className="input">
                          <label>Services Role:</label>
                          <Input placeholder="" name="name" value={this.props.sowBuilderOverageHours > 0 ? 'MS and MS Overage' : 'MS'} disabled/>
                        </div>
                        <div className="input">
                          <label>Duration:</label>
                          <Input placeholder="" name="name" value={this.props.sowBuilderTerm + ' Months'} disabled/>
                        </div>
                        <div className="input">
                          <label>Contract Hours:</label>
                          <Input placeholder="" name="name" value={this.props.sowBuilderHoursPerMonth} disabled/>
                        </div>
                        <div className='input' >
                          <label>Requested Resource Location:</label>
                          <Select value={this.state.salesforce.reqresource} onChange={(e) => this.sfHandleSelectChange(e,'reqresource')}
                            options= {this.props.getSfData().regions.map(function(dept) {
                              return {label:dept, value:dept}
                            })} />
                        </div>
                        <div className="input">
                          <label>Product:</label>
                          <Input placeholder="" name="name" value={this.props.data.name.includes('Vault') ? 'Vault' : 'CRM'} disabled/>
                        </div>
                      </div>
                    </div>
                    <Button className="submit" onClick={this.onSfSubmit}>Save</Button>
                    {this.state.hasSubmitted && !this.areFieldsValid() && <Alert color="danger">Please fill in all required fields.</Alert>}
                  </div>
                </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="poweredBy">Powered by <a href="https://www.workrails.com" target="_blank" rel="noopener noreferrer">WorkRails</a></div>
          </ModalFooter>
        </Modal>
    );
  }

  render() {
    const subtitle = this.props.renderProductPriceTime(this.props.data, {item: true});
    return (
      <Container className="detail">
        {this.props.isSalesforce && this.getModal()}
          <Link to="/"
                onClick={(e) => {this.props.backToPage(e); this.props.log_transaction('viewed', 'page', 'home'); }}
                color="link"
                className="backButton"
          >
            <FontAwesomeIcon icon="long-arrow-alt-left" />
            Back
          </Link>
        {this.props.data && this.props.data.description ? (
          <Card onClick={this.handleSelect} className={`${this.props.isSowBuilderPreviewShowing() ? "preview-showing" : ""} ${!this.props.isSowBuilderEmpty() ? "has-products" : ""}`}>
            <CardHeader>
              <CardTitle>{this.props.data.name}</CardTitle>
              {subtitle && <CardSubtitle>{subtitle}</CardSubtitle>}
              <div className="cta">
                {this.props.isSowBuilderAdded(this.props.data) ? (
                  <button
                    className="added"
                    onClick={() =>
                      this.props.handleSowBuilderRemove(this.props.data)
                    }
                  >
                    Added to SOW
                  </button>
                ) :
                    <button className="add" onClick={() => this.props.handleSowBuilderAdd(this.props.data) }>Add to SOW</button>
                }
              </div>
            </CardHeader>
            <CardBody style={{ overflow: "auto" }}>
              <label style={{ marginBottom: '50px', display: 'block' }}>
                <span style={{ color: 'rgba(0, 0, 0, 0.5)', fontStyle: 'italic', display: 'block', marginBottom: '6px' }}>Please note you must have discount approval from the appropriate Professional Services leadership before adding a discount to this item. Email <a href="mailto:psdealdesk@gainsight.com">psdealdesk@gainsight.com</a> to help facilitate approval.</span>
                Discount %:
                {' '}
                <input
                  onChange={this.handleDiscountChange}
                  value={this.props.selectedProductOptions[this.getDiscountKey()] || 0}
                  min="0"
                  max="20"
                  name="discountPercent"
                  type="number"
                />
              </label>
              <WorkrailsInterpreter
                  selectedProductOptions={this.props.selectedProductOptions}
                  handleCheckOption={this.handleCheckOption}
                  getProductOptionState={this.props.getProductOptionState}
                  handleProductOptionSelection={this.props.handleProductOptionSelection}
                  getMultiplierOptionDefault={this.props.getMultiplierOptionDefault}
                  isMultiplierOption={this.props.isMultiplierOption}
                  isCheckboxOptionDisabled={this.isCheckboxOptionDisabled}
                  hideTime={true}
                  isTextDisabled={this.isTextDisabled}
                  isSowBuilderLimit={this.props.isSowBuilderLimit}
                  isLoggedIn={this.props.isLoggedIn}
                  data={this.props.data}
                  editProposal={this.props.editProposal}
                  proposalCreate={this.props.proposalCreate}
              />

            </CardBody>
          </Card>
        ) : (
          <h1>Something went wrong.</h1>
        )}
        {this.props.getSowBuilderSummary(this.props.data)}
      </Container>
    );
  }
}

export default ProductDetail;
