import React, { Component } from "react";
import { withRouter, Route } from "react-router-dom";
import {
  Container,
  Row
} from "reactstrap";
import ProposalBuilder from "./Proposal/ProposalBuilder.js";
import ProposalListView from "./Proposal/ProposalListView.js";

class Page extends Component {
  constructor(props) {
    super(props);
    this.state = {
      heroAnimate: true,
    };
    this.sfHandleAccountChange = this.sfHandleAccountChange.bind(this);
    this.pageCreateOpportunity = this.pageCreateOpportunity.bind(this);
    this.toggleSfModal = this.toggleSfModal.bind(this);
    this.heroAnimate = this.heroAnimate.bind(this);

    this.props.history.listen((location) => {
      if(location.pathname.indexOf('/item') !== 0 && this.props.detailItem) {
        this.props.setPageDetail();
      }
      this.heroAnimate();
    });

    this.backToPage = this.backToPage.bind(this);
  }

  backToPage() {
    this.props.setPageDetail();
    this.props.setPageProposals(false);
  }

  toggleSfModal(){
    if(this.state.sfLoading){
      return;
    }

    if(!this.state.hasSubmitted){
      this.setState({hasSubmitted: true});
    }

    if(!this.state.isSfModalOpen){
      this.handleSowBuilderDownload('word', undefined, true);
      this.handleSowBuilderDownload('pdf', undefined, true);
    }

    if(this.state.finalMsg){
      this.setState({finalMsg: undefined});
      this.setState({isSfModalOpen: true});
      return;
    }

    if(!this.areFieldsValid()){
      this.setState({oppError: 'Please fill out all required fields'});
      return;
    }
    this.setState({isSfModalOpen: !this.state.isSfModalOpen});
  }

  areFieldsValid(){
    return (this.state.sowBuilderClient !== ''&& this.state.sowBuilderDate !== '');
  }

  heroAnimate() {
    this.setState({heroAnimate: true}, () => {
      this.setState({heroAnimate: false});
    });
  }

  componentDidMount() {
    this.heroAnimate();
  }

  sfHandleAccountChange(selection){
    this.setState({sowBuilderClient: selection.label});
    this.setState({sfAccount:selection});
  }

  pageCreateOpportunity(opp, docName, staffing, order){
    this.setState({isSfModalOpen: false});
    this.setState({sfLoading: true});
    this.props.sfCreateOpportunity(opp, this.state.sfWord, this.state.sfPdf, docName, staffing, order, this.handleError);
  }

  handleError(msg){
    this.setState({sfLoading: false});
    this.toggleSfModal();
    this.setState({finalMsg: msg});
  }

  clearError(){
    this.setState({oppError: null});
  }

  render(){
    const data = this.props.data;
    return (
        <div>
          <Route
              exact
              path={["/", "/item/:id", "/proposals/:editProposalId"]}
              render={() => {
                return(
                    <div className="page">
                      <div className={`hero ${this.state.heroAnimate ? 'animate' : ''}`}>
                        <div className="container">
                          <div className="titles">
                            <Container>
                              <Row>
                                <h1 className="title">{data.name}</h1>
                                <h2 className="subtitle">{data.description}</h2>
                              </Row>
                            </Container>
                          </div>
                        </div>
                      </div>
                      <ProposalBuilder
                          data={data}
                          user={this.props.user}
                          isLoggedIn={this.props.isLoggedIn}
                          isDimmed={this.props.isDimmed}
                          toggleLoginModal={this.props.toggleLoginModal}
                          detailItem={this.props.detailItem}
                          log_transaction={this.props.log_transaction}
                          setPageDetail={this.props.setPageDetail}
                          setPageProposals={this.props.setPageProposals}
                          proposalLazyCreate={this.props.proposalLazyCreate}
                          proposalCreate={this.props.proposalCreate}
                          proposalLazyUpdate={this.props.proposalLazyUpdate}
                          proposalUpdate={this.props.proposalUpdate}
                          proposalQuoteLazyCreate={this.props.proposalQuoteLazyCreate}
                          proposalQuoteCreate={this.props.proposalQuoteCreate}
                          proposalLazyFetch={this.props.proposalLazyFetch}
                          proposalFetch={this.props.proposalFetch}
                      />
                    </div>
                );
              }}
          />
          <Route
              exact
              path="/proposals"
              render={() => {
                return (
                    <ProposalListView
                        data={data}
                        user={this.props.user}
                        isLoggedIn={this.props.isLoggedIn}
                        toggleLoginModal={this.props.toggleLoginModal}
                        backToPage={this.backToPage}
                        isLoading={this.props.isLoading}
                        isDimmed={this.props.isDimmed}
                        detailItem={this.props.detailItem}
                        setPageDetail={this.props.setPageDetail}
                        setPageProposals={this.props.setPageProposals}
                        proposalsLazyFetch={this.props.proposalsLazyFetch}
                        proposalsFetch={this.props.proposalsFetch}
                        proposalLazyCreate={this.props.proposalLazyCreate}
                        proposalCreate={this.props.proposalCreate}
                        proposalLazyUpdate={this.props.proposalLazyUpdate}
                        proposalUpdate={this.props.proposalUpdate}
                        proposalLazyDelete={this.props.proposalLazyDelete}
                        proposalDelete={this.props.proposalDelete}
                        proposalQuoteLazyCreate={this.props.proposalQuoteLazyCreate}
                        proposalQuoteCreate={this.props.proposalQuoteCreate}
                        proposalQuotesFetch={this.props.proposalQuotesFetch}
                        log_transaction={this.props.log_transaction}
                    />
                );
              }}
          />
        </div>
    );
  }
}

export default withRouter(Page);
