const Constants =
  window.location.href.indexOf('localhost') !== -1 || window.location.href.indexOf('.dev.') !== -1 ?
      {
        API_BASE: "https://api.workrails.com/beta",
        APP_BASE: "https://app.workrails.com",
        CATALOG_ID: "62b38c09-78ed-45b1-bcc5-1d1dcbfb6c53",
        COMPANY_ID: "7953886e-6b1f-4076-9433-c6ecb49baf90",
        AUTH: process.env.REACT_APP_WR_ACCESS_TOKEN
      } :
      {
        API_BASE: "https://api.workrails.com/beta",
        APP_BASE: "https://app.workrails.com",
        CATALOG_ID: "23c6d155-6f46-496e-8491-d9b738a5fcde",
        COMPANY_ID: "7953886e-6b1f-4076-9433-c6ecb49baf90",
        AUTH: process.env.REACT_APP_WR_ACCESS_TOKEN
      }
;
export default Constants;
