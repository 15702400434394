import React, { Component } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import {
  Container,
  Button,
  Table,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Alert,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from 'moment';
import classNames from "classnames";
import ProposalBuilder from "./ProposalBuilder";

class ProposalListView extends Component {
  constructor(props) {
    super(props);

    this.state = {
      proposals: [],
      statusQuoteProposalBuilderLoaded: {},
      statusDropdownOpen: false,
      actionDropdownOpen: false,
      editProposal: null
    };

    this.loadStatusQuoteProposalBuilder = this.loadStatusQuoteProposalBuilder.bind(this);
    this.unloadStatusQuoteProposalBuilder = this.unloadStatusQuoteProposalBuilder.bind(this);
    this.toggleStatusDropdown = this.toggleStatusDropdown.bind(this);
    this.statusDropdownOpen = this.statusDropdownOpen.bind(this);
    this.toggleActionDropdown = this.toggleActionDropdown.bind(this);
    this.actionDropdownOpen = this.actionDropdownOpen.bind(this);
    this.toggleEditProposal = this.toggleEditProposal.bind(this);
    this.handleEdit = this.handleEdit.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    this.props.isLoading(true);
    this.props.proposalsLazyFetch();
    this.props.setPageProposals();
  }

  componentDidUpdate(prevProps) {
    if (_.get(prevProps, 'proposalsFetch.pending') && !_.get(this.props, 'proposalsFetch.pending')) {
      this.setState({proposals: (_.get(this.props, 'proposalsFetch.value.data') || []).map(proposal => {
          proposal.settings = JSON.parse(proposal.settings);
          return proposal;
        })});
    }
    if (_.get(prevProps, 'proposalQuotesFetch.pending') && !_.get(this.props, 'proposalQuotesFetch.pending')) {
      const quotes = _.get(this.props, 'proposalQuotesFetch.value.data');
      this.setState({proposals: this.state.proposals.map(proposal => {
          proposal.quote = (_.find(quotes, { 'attachableId': proposal.id })||{}).path;
          return proposal;
        })});
    }
    if(_.get(prevProps, 'proposalUpdate.pending') && !_.get(this.props, 'proposalUpdate.pending')) {
      this.props.proposalsLazyFetch();
      this.toggleEditProposal();
    }
    if(_.get(prevProps, 'proposalQuoteCreate.pending') && !_.get(this.props, 'proposalQuoteCreate.pending')) {
      this.props.proposalsLazyFetch();
    }
    if(_.get(prevProps, 'proposalDelete.pending') && !_.get(this.props, 'proposalDelete.pending')) {
      this.props.proposalsLazyFetch();
    }
  }

  loadStatusQuoteProposalBuilder(proposal) {
    const { statusQuoteProposalBuilderLoaded } = this.state;
    statusQuoteProposalBuilderLoaded[proposal.id] = '';
    this.setState({ statusQuoteProposalBuilderLoaded });
  }
  unloadStatusQuoteProposalBuilder(proposal) {
    const { statusQuoteProposalBuilderLoaded } = this.state;
    delete statusQuoteProposalBuilderLoaded[proposal.id];
    this.setState({ statusQuoteProposalBuilderLoaded });
  }

  toggleStatusDropdown(proposal) {
    this.setState({ statusDropdownOpen: this.state.statusDropdownOpen !== proposal ? proposal : null });
    this.loadStatusQuoteProposalBuilder(proposal);
  }

  statusDropdownOpen(proposal) {
    return this.state.statusDropdownOpen === proposal;
  }

  handleStatusChange(proposal, status) {
    this.props.isLoading(true);
    this.props.proposalLazyUpdate({id: proposal.id, status});
  }

  toggleActionDropdown(proposal) {
    this.setState({ actionDropdownOpen: this.state.actionDropdownOpen !== proposal ? proposal : null });
  }

  actionDropdownOpen(proposal) {
    return this.state.actionDropdownOpen === proposal;
  }

  handleDelete(proposal) {
    this.props.isLoading(true);
    this.props.proposalLazyDelete(proposal.id);
  }

  handleEdit(proposal) {
    this.props.setPageDetail();
    this.setState({editProposal: proposal});
    this.unloadStatusQuoteProposalBuilder(proposal);
  }

  toggleEditProposal() {
    this.setState({editProposal: null});
  }

  render() {
    return (
        <div className="page">
          <Container className={classNames("proposalList", {empty: !this.state.proposals.length })}>
            <Button
                onClick={this.props.backToPage}
                color="link"
                className="backButton"
            >
              <Link to="/">
                <FontAwesomeIcon icon="long-arrow-alt-left" />
                Catalog
              </Link>
            </Button>
            { this.state.proposals.length ?
                <Table bordered>
                  <thead>
                  <tr>
                    <th>SOW Name</th>
                    <th>Client Name</th>
                    <th>Created</th>
                    <th>Price</th>
                    <th>Status</th>
                    <th>Expiration</th>
                    <th>Actions</th>
                  </tr>
                  </thead>
                  <tbody>
                  {_.sortBy(this.state.proposals, proposal => new moment(proposal.settings.issueDate)).reverse().map(proposal => {
                    const settings = proposal.settings;
                    return (
                        <tr key={proposal.id}>
                          <td>
                            <div>
                              <Button color="link" onClick={() => this.handleEdit(proposal)}>{proposal.name}</Button>
                              {_.includes(proposal.status, 'quote') ? <a href={proposal.quote} target="_blank" rel="noopener noreferrer" className="quote"><FontAwesomeIcon icon="paperclip" /></a> : ''}
                            </div>
                          </td>
                          <td>{settings.clientName || '-'}</td>
                          <td>{settings.issueDate ? moment(settings.issueDate).format('M/D/YY') : '-'}</td>
                          <td>${(settings.value ? settings.value/100 : 0).toLocaleString()}</td>
                          <td>
                              <Dropdown isOpen={this.statusDropdownOpen(proposal)} toggle={() => this.toggleStatusDropdown(proposal)}>
                                <DropdownToggle caret color="link" className={classNames({ submitting: this.state.proposalSubmitting })}>
                                  {_.startCase(_.includes(proposal.status, 'quote') ? 'quote' : proposal.status)}
                                </DropdownToggle>
                                <DropdownMenu>
                                  <DropdownItem className="word" onClick={() => this.handleStatusChange(proposal,'quoteWord')}>As Quote</DropdownItem>
                                  {/*<DropdownItem className="pdf" onClick={() => this.handleStatusChange(proposal,'quotePdf')}>As Quote</DropdownItem>*/}
                                </DropdownMenu>
                              </Dropdown>

                              {_.has(this.state.statusQuoteProposalBuilderLoaded, proposal.id) &&
                              <ProposalBuilder
                                  data={this.props.data}
                                  user={this.props.user}
                                  isDimmed={this.props.isDimmed}
                                  proposalCreate={this.props.proposalCreate}
                                  proposalUpdate={this.props.proposalUpdate}
                                  proposalQuoteLazyCreate={this.props.proposalQuoteLazyCreate}
                                  proposalQuoteCreate={this.props.proposalQuoteCreate}
                                  editProposal={proposal}
                                  log_transaction={this.props.log_transaction}
                              />}
                          </td>
                          <td>{settings.expiryDate ? moment(settings.expiryDate).format('M/D/YY') : '-'}</td>
                          <td>
                            <Dropdown isOpen={this.actionDropdownOpen(proposal)} toggle={() => this.toggleActionDropdown(proposal)}>
                              <DropdownToggle color="link">
                                <FontAwesomeIcon icon="ellipsis-v" />
                              </DropdownToggle>
                              <DropdownMenu>
                                {/*<DropdownItem>Archive</DropdownItem>*/}
                                <DropdownItem onClick={() => this.handleEdit(proposal)}>Edit</DropdownItem>
                                <DropdownItem onClick={() => this.handleDelete(proposal)}>Delete</DropdownItem>
                              </DropdownMenu>
                            </Dropdown>

                          </td>
                        </tr>
                    )
                  })}
                  </tbody>
                </Table> :
                !this.props.isLoading() && <Alert color="danger">You don't have any saved SOWs at this time</Alert>}
          </Container>

          <Modal isOpen={!!this.state.editProposal} toggle={this.toggleEditProposal} size="lg" className="editProposal">
            <ModalHeader toggle={this.toggleEditProposal}>
            {this.state.editProposal && this.state.editProposal.name}
            </ModalHeader>
            <ModalBody>
              <ProposalBuilder
                  data={this.props.data}
                  user={this.props.user}
                  isLoggedIn={this.props.isLoggedIn}
                  isDimmed={this.props.isDimmed}
                  toggleLoginModal={this.props.toggleLoginModal}
                  detailItem={this.props.detailItem}
                  setPageDetail={this.props.setPageDetail}
                  setPageProposals={this.props.setPageProposals}
                  proposalLazyCreate={this.props.proposalLazyCreate}
                  proposalCreate={this.props.proposalCreate}
                  proposalLazyUpdate={this.props.proposalLazyUpdate}
                  proposalUpdate={this.props.proposalUpdate}
                  proposalQuoteLazyCreate={this.props.proposalQuoteLazyCreate}
                  proposalQuoteCreate={this.props.proposalQuoteCreate}
                  editProposal={this.state.editProposal}
                  log_transaction={this.props.log_transaction}
              />
            </ModalBody>
          </Modal>

        </div>
    );
  }
}

export default ProposalListView;
